import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import get from "lodash/get";
import { type ComponentPropsWithoutRef, useState } from "react";
import type { FieldError, FieldValues } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { Checkbox } from "~/components/inputs/checkbox";
import { InputContainer } from "~/components/inputs/field-container";
import type { RHFInputProps, RHFLabeledInputProps } from "~/types/forms/inputs";
type RHFLabeledCheckboxProps<T> = RHFLabeledInputProps<T> & Omit<ComponentPropsWithoutRef<"input">, "name"> & {
  text?: string;
};
type RHFCheckboxProps<T> = RHFInputProps<T> & Omit<ComponentPropsWithoutRef<"input">, "name"> & {
  text?: string;
  checkboxClassName?: string;
};
export const RHFCheckbox = <T extends FieldValues,>({
  name,
  rules,
  className,
  required,
  checkboxClassName,
  text,
  ...props
}: RHFCheckboxProps<T>) => {
  const {
    register,
    formState: {
      errors
    },
    control
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...
  const isChecked = useWatch({
    control,
    name
  });
  const id = crypto.randomUUID();
  const [hover, setHover] = useState(false);
  return <div className={clsx("flex items-center space-x-2", className)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} data-sentry-component="RHFCheckbox" data-sentry-source-file="checkbox.tsx">
      {props.readOnly && isChecked && <CheckIcon className="text-success h-4 w-4" />}
      {props.readOnly && !isChecked && <XMarkIcon className="text-error h-5 w-5" />}
      {!props.readOnly && <Checkbox id={id} className={checkboxClassName} isError={!!error} {...props} {...register(name, {
      ...rules,
      required
    })} />}

      {text && <label htmlFor={id} className={clsx("font-normal transition-colors", {
      "text-neutral-600": props.disabled,
      "text-neutral-950": !props.disabled && !props.readOnly && (isChecked || hover),
      "text-neutral-700": !props.disabled && !isChecked || props.readOnly
    })}>
          {text}
        </label>}
    </div>;
};
export const RHFLabeledCheckbox = <T extends FieldValues,>({
  description,
  label,
  containerClassName,
  ...props
}: RHFLabeledCheckboxProps<T>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, props.name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...
  return <InputContainer readOnly={props.readOnly} label={label} name={props.name} required={props.required} error={error} description={description} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledCheckbox" data-sentry-source-file="checkbox.tsx">
      <RHFCheckbox {...props} data-sentry-element="RHFCheckbox" data-sentry-source-file="checkbox.tsx" />
    </InputContainer>;
};