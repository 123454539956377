import { ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { InputContainer } from "~/components/inputs/field-container";
import { emptyInputClassName } from "~/components/inputs/utils";
import { Typography } from "~/components/typography";
import { TEXT_INPUT_CLASSNAMES } from "~/constants/inputs";
import type { MaxChar } from "~/types/dispositif";
import type { InputProps, LabeledInputProps, OmittedFieldsWhenReset, ResetForInputProps } from "~/types/forms/inputs";
type TextInputProps = React.ComponentPropsWithRef<"input"> & InputProps;
type LabeledTextInputProps = React.ComponentPropsWithRef<"input"> & LabeledInputProps & {
  maxChar?: MaxChar;
};
type TextInputWithResetProps = Omit<TextInputProps, OmittedFieldsWhenReset> & ResetForInputProps;
type LabeledTextInputWithResetProps = Omit<LabeledTextInputProps, OmittedFieldsWhenReset> & ResetForInputProps;
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput({
  unstyled = false,
  isError,
  className,
  ...props
}, forwardedRef) {
  if (props.readOnly) {
    return <Typography.body className={clsx(!props.value && emptyInputClassName, className)}>{props.value ? props.value : "/"}</Typography.body>;
  }
  return <input id={props.name} ref={forwardedRef} className={clsx(!unstyled && [TEXT_INPUT_CLASSNAMES, isError ? "border-error" : "border-grey-blue-80", "px-2 py-2 placeholder:text-neutral-600 text-neutral-900 placeholder:italic rounded-[3px] disabled:bg-neutral-100"], className)} {...props} />;
});
export const LabeledTextInput: React.FC<LabeledTextInputProps> = ({
  containerClassName,
  label,
  description,
  name,
  error,
  required,
  maxChar,
  ...props
}) => {
  return <InputContainer name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledTextInput" data-sentry-source-file="text.tsx">
      <TextInput isError={Boolean(error)} id={name} {...props} {...maxChar && maxChar !== "unlimited" && {
      maxLength: parseInt(maxChar)
    }} data-sentry-element="TextInput" data-sentry-source-file="text.tsx" />
    </InputContainer>;
};
export const TextInputWithReset = forwardRef<HTMLInputElement, TextInputWithResetProps>(function TextInputWithReset({
  handleReset,
  className,
  ...props
}, forwardedRef) {
  return <div className="flex w-full justify-between">
      <TextInput ref={forwardedRef} onReset={() => null} {...props} unstyled className={clsx(TEXT_INPUT_CLASSNAMES, "block w-full border-neutral-400 py-2 pl-2 pr-8", props.value && "border-r-0", className)} />
      {props.value && <div className="flex select-none items-center border border-l-0 px-3 bg-white">
          <button type="button" className="h-full" onClick={handleReset}>
            <ArrowPathIcon className="h-5 w-5" />
          </button>
        </div>}
    </div>;
});
export const LabeledTextInputWithReset: React.FC<LabeledTextInputWithResetProps> = ({
  containerClassName,
  label,
  description,
  name,
  error,
  required,
  maxChar,
  ...props
}) => {
  return <InputContainer name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledTextInputWithReset" data-sentry-source-file="text.tsx">
      <TextInputWithReset className="w-full" id={name} {...props} {...maxChar && maxChar !== "unlimited" && {
      maxLength: parseInt(maxChar)
    }} data-sentry-element="TextInputWithReset" data-sentry-source-file="text.tsx" />
    </InputContainer>;
};
export const SearchInput = forwardRef<HTMLInputElement, TextInputProps & {
  className?: string;
}>(function SearchInput({
  className,
  ...props
}, forwardedRef) {
  return <div className={clsx("relative rounded-[3px] w-full", className)}>
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon className="h-5 w-5 text-neutral-600 stroke-2" />
      </div>
      <input id={props.name} ref={forwardedRef} className="text-note block w-full rounded-[3px] border-0 py-1.5 pl-11 text-neutral-800 bg-neutral-100 ring-1 ring-inset ring-grey-blue-60 placeholder:text-neutral-600 placeholder:italic focus:ring-inset focus:ring-neutral-500" {...props} />
    </div>;
});