import get from "lodash/get";
import type { ComponentPropsWithoutRef } from "react";
import type { FieldError, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { DatePicker } from "~/components/inputs/date";
import { InputContainer } from "~/components/inputs/field-container";
import type { RHFInputProps, RHFLabeledInputProps } from "~/types/forms/inputs";
export type RHFDatePickerProps<T> = RHFInputProps<T> & Omit<ComponentPropsWithoutRef<"input">, "name">;
type RHFLabeledDatePickerProps<T> = RHFLabeledInputProps<T> & RHFDatePickerProps<T>;
export const RHFLabeledDatePicker = <T extends FieldValues,>({
  name,
  label,
  description,
  rules,
  containerClassName,
  required,
  ...props
}: RHFLabeledDatePickerProps<T>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...;

  // We use a controller here because rhf is bad at handling input dates defaultValues or resets are not the same way as value making it hard to implement without hacks,...
  return <InputContainer readOnly={props.readOnly} label={label} description={description} name={name} error={error} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledDatePicker" data-sentry-source-file="date.tsx">
      <RHFDatePicker name={name} rules={rules} required={required} {...props} data-sentry-element="RHFDatePicker" data-sentry-source-file="date.tsx" />
    </InputContainer>;
};
export const RHFDatePicker = <T extends FieldValues,>({
  name,
  rules,
  required,
  ...props
}: RHFDatePickerProps<T>) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...;

  // We use a controller here because rhf is bad at handling input dates defaultValues or resets are not the same way as value making it hard to implement without hacks,...

  return <Controller control={control} name={name} rules={{
    ...rules,
    required
  }} render={({
    field
  }) => {
    return (
      // Need to use this DatePicker because we need the native event
      <DatePicker isError={Boolean(error)} onBlur={field.onBlur} ref={field.ref} value={field.value} onChange={field.onChange} name={field.name} {...props} />
    );
  }} data-sentry-element="Controller" data-sentry-component="RHFDatePicker" data-sentry-source-file="date.tsx" />;
};