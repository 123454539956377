import get from "lodash/get";
import { type ComponentPropsWithoutRef } from "react";
import type { FieldError, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "~/components/inputs/field-container";
import { RichtextInput } from "~/components/inputs/richtext";
import type { MaxChar } from "~/types/dispositif";
import type { RHFLabeledInputProps } from "~/types/forms/inputs";
type ReactHookFormRichtextFieldProps<T> = RHFLabeledInputProps<T> & Omit<ComponentPropsWithoutRef<typeof RichtextInput>, "name" | "onChange" | "initialValue" | "maxChar"> & {
  maxChar?: MaxChar;
};
export const RHFLabeledRichtext = <T extends FieldValues,>({
  name,
  label,
  description,
  rules,
  containerClassName,
  required,
  maxChar,
  ...props
}: ReactHookFormRichtextFieldProps<T>) => {
  const {
    control,
    formState
  } = useFormContext();
  const error = (get(formState.errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...;

  // We use a controller here because rhf is bad at handling input Richtexts defaultValues or resets are not the same way as value making it hard to implement without hacks,...
  return <InputContainer readOnly={props.readOnly} label={label} description={description} name={name} error={error} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledRichtext" data-sentry-source-file="richtext.tsx">
      <Controller control={control} name={name} rules={{
      ...rules,
      required
    }} render={({
      field
    }) => {
      return <RichtextInput isError={Boolean(error)} onChange={field.onChange} value={field.value ?? undefined} name={field.name} {...maxChar && maxChar !== "unlimited" && {
        maxChar: parseInt(maxChar)
      }} {...props} />;
    }} data-sentry-element="Controller" data-sentry-source-file="richtext.tsx" />
    </InputContainer>;
};