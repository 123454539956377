import get from "lodash/get";
import type { KeyOption } from "match-sorter";
import type { FieldError, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { DropdownSelect } from "~/components/inputs/dropdown-select";
import { InputContainer } from "~/components/inputs/field-container";
import type { RHFInputProps, RHFLabeledInputProps } from "~/types/forms/inputs";
import type { MultiOption } from "~/types/forms/multi-option";
export type RHFDropdownSelectProps<T, Option> = RHFInputProps<T> & {
  isMultipleChoice?: boolean;
  placeholder?: string;
  options: Option[];
  keys: readonly KeyOption<Option>[];
};
type RHFLabeledDropdownSelectProps<T, Option> = RHFLabeledInputProps<T> & RHFDropdownSelectProps<T, Option>;
export const RHFLabeledDropdownSelect = <T extends FieldValues, Option extends MultiOption>({
  name,
  label,
  description,
  containerClassName,
  required,
  ...props
}: RHFLabeledDropdownSelectProps<T, Option>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...;

  return <InputContainer label={label} description={description} name={name} error={error} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledDropdownSelect" data-sentry-source-file="dropdown-select.tsx">
      <RHFDropdown name={name} required={required} {...props} data-sentry-element="RHFDropdown" data-sentry-source-file="dropdown-select.tsx" />
    </InputContainer>;
};
export const RHFDropdown = <T extends FieldValues, Option extends MultiOption>({
  rules,
  name,
  required,
  options,
  keys,
  // Keys is the element on which we will match the input value from the Options (type) keys: ["label"] or ["value"] or ["label", "value"]
  ...props
}: RHFDropdownSelectProps<T, Option>) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...

  return <Controller control={control} name={name} rules={{
    ...rules,
    required
  }} render={({
    field
  }) => {
    return <DropdownSelect isError={Boolean(error)} onChange={value => field.onChange(value)} options={options} value={field.value ?? []} keys={keys} {...props} />;
  }} data-sentry-element="Controller" data-sentry-component="RHFDropdown" data-sentry-source-file="dropdown-select.tsx" />;
};
export const RHFLabeledDropdownSingleSelect = <T extends FieldValues, Option extends MultiOption>({
  name,
  label,
  description,
  rules,
  containerClassName,
  required,
  options,
  keys,
  disabled,
  onChange,
  ...props
}: Omit<RHFLabeledDropdownSelectProps<T, Option>, "isMultipleChoice"> & {
  onChange?: () => void;
}) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined | {
    label: FieldError;
  }[]); // FIXME: Hack but cannot make the right type work...;

  let errorMessage;
  if (Array.isArray(error) && error.length > 0) {
    errorMessage = error[0].label.message;
  }
  return <InputContainer disabled={disabled} label={label} description={description} name={name} error={errorMessage ?? (error as FieldError | undefined)} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledDropdownSingleSelect" data-sentry-source-file="dropdown-select.tsx">
      <Controller control={control} name={name} rules={{
      ...rules,
      required
    }} render={({
      field
    }) => {
      return <DropdownSelect isError={Boolean(error)} onChange={value => {
        if (value && value.length > 0) field.onChange(value[0].id);else field.onChange(null);
        onChange && onChange();
      }} options={options} value={field.value ? options.filter(option => option.id === field.value) : []} keys={keys} {...props} />;
    }} data-sentry-element="Controller" data-sentry-source-file="dropdown-select.tsx" />
    </InputContainer>;
};