import type { FieldError, FieldValues } from "react-hook-form";
import { Controller, get, useFormContext } from "react-hook-form";
import type { InputAttributes, NumberFormatValues, NumericFormatProps } from "react-number-format";
import { InputContainer } from "~/components/inputs/field-container";
import { MoneyInput } from "~/components/inputs/money";
import type { Currency } from "~/types/dispositif";
import type { RHFInputProps, RHFLabeledInputProps } from "../../../types/forms/inputs";
export type RHFMoneyProps<T> = RHFInputProps<T> & NumericFormatProps<InputAttributes> & {
  className?: string;
  currency?: Currency;
};
type RHFLabeledMoneyProps<T> = RHFLabeledInputProps<T> & RHFMoneyProps<T>;
export const RHFLabeledMoneyInput = <T extends FieldValues,>({
  label,
  description,
  rules,
  name,
  containerClassName,
  required,
  ...props
}: RHFLabeledMoneyProps<T>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...

  return <InputContainer readOnly={props.readOnly} label={label} description={description} name={name} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledMoneyInput" data-sentry-source-file="money.tsx">
      <RHFMoney name={name} rules={rules} required={required} {...props} data-sentry-element="RHFMoney" data-sentry-source-file="money.tsx" />
    </InputContainer>;
};
export const RHFMoney = <T extends FieldValues,>({
  rules,
  name,
  required,
  ...props
}: RHFMoneyProps<T>) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = (get(errors, name) as FieldError | undefined); // FIXME: Hack but cannot make the right type work...

  return <Controller control={control} name={name} rules={{
    ...rules,
    required
  }} render={({
    field
  }) => <MoneyInput isError={Boolean(error)}
  // we need the value to be null in the case it's undefined or it crashes
  onValueChange={(values: NumberFormatValues) => field.onChange(values.floatValue !== undefined ? values.floatValue : null)} value={field.value} name={field.name} {...props} />} data-sentry-element="Controller" data-sentry-component="RHFMoney" data-sentry-source-file="money.tsx" />;
};